@import '~@/styles/index.scss';

.base-action-menu {
    padding-top: 4px !important;
    padding-bottom: 12px;
    max-width: 600px;

    .v-toolbar__title {
        min-width: 160px;
        font-size: 16px;
        padding: 10px 15px 10px 15px;
        font-weight: 500;
    }

    .v-menu--inline {
        display: none !important;
    }
}

.mobile .base-action-menu {
    max-width: 100%;
    width: 100%;
}
