@import '~@/styles/index.scss';

.base-menu-sheet-header {
    background: var(--v-background-base);
    position: fixed;
    width: 100%;
    z-index: 1;
}

.base-menu-sheet-container {
    padding-top: 50px;
    background: var(--v-background-base);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: 90vh;
    max-height: -webkit-fill-available;
}
