@import '~@/styles/index.scss';

.base-dialog-header {
    width: 100%;
    background: var(--v-background-base);
    position: fixed;
    z-index: 1;
    padding: 0 0 0 0;

    .search-field {
        margin-left: 12px;
        margin-right: 12px;
        margin-bottom: 8px;
    }

    .close-col {
        padding-right: 0;
    }

    .v-toolbar {
        height: 50px !important;
    }

    .v-toolbar__content {
        max-height: 50px;

        .v-toolbar__title {
            font-size: 19px;
            padding-left: 0 !important;
        }
    }
}
