.prism-editor-wrapper {
  height: 100%;
  overflow-y: auto;

  pre {
    margin-right: 3px !important;
  }

  pre[class*="language-"] > code {
    border-left: 6px solid var(--v-accent-base);
    min-width: 100%;
  }

  .prism-editor__code {
    padding: 0;
    background-color: transparent;
    outline: none;
  }

  code:before {
    display: none;
  }
}