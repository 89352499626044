@import '~@/styles/index.scss';

.init-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;

    border: 4px solid transparent;
    border-top-color: #4a1d61;
    border-bottom-color: #3a0d51;
    border-radius: 50%;
    -webkit-animation: loader 1s linear infinite;
    -moz-animation: loader 1s linear infinite;
    -o-animation: loader 1s linear infinite;
    animation: loader 1s linear infinite;
}

.ppds-theme {
    .init-content {
        border-top-color: #0f5291;
        border-bottom-color: #0f5291;
    }
}

.uniguest-theme {
    .init-content {
        border-top-color: #FFC52B;
        border-bottom-color: #FFC52B;
    }
}
