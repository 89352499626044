@import '~@/styles/index.scss';


@font-face {
    font-family: "ProximaVara";
    src: url("../public/fonts/ProximaVara.woff2");
}

.v-application {
    background-color: var(--v-background-base) !important;
}

.uniguest-theme {
    font-family: ProximaVara;

    .v-btn:hover:not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.error--text):not(.v-btn--rounded) {
        background-color: var(--v-accent-base) !important;
        transition: background-color .28s;
    }

    .v-btn:hover:is(.error--text):not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.v-btn--rounded) {
        background-color: var(--v-error-darken4) !important;
        transition: background-color .28s;
    }

    .v-btn:hover:is(.error):not(.base-dialog-btn-cancel):not(.dashboard-toggle-btn):not(.v-btn--icon):not(.folder-tree *):not(.v-btn--rounded) {
        background-color: var(--v-error-darken4) !important;
        transition: background-color .28s;
    }

    .v-btn:not(.v-btn--icon):not(.dashboard-toggle-btn):not(.v-btn--disabled):not(.base-dialog-btn-cancel):not(.v-btn--rounded) {
        color: white !important;
        background-color: var(--v-primary-base) !important;
    }

    .base-dialog-btn-cancel {
        box-shadow: none !important;
        border: 1px solid #BFBFBF !important;
    }
}

.application {
    background-color: var(--v-background-base) !important;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-text-fill-color: inherit !important;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0 30px var(--v-background-base) inset !important;
    }
}

.theme--dark {
    .v-data-table,
    .v-card,
    .v-list {
        background-color: var(--v-background-base) !important;
    }
    .v-expansion-panel {
        background-color: #4a4a4a !important;
    }
    .apexcharts-menu {
        background: #333 !important;
    }
    .apexcharts-tooltip {
        background: #333 !important;
    }
    .apexcharts-theme-light .apexcharts-menu-item:hover {
        background: #111 !important;
    }
    .apexcharts-tooltip-title {
        background: #333 !important;
    }
}

body {
    background: var(--v-background-base) !important;
}
