@import '~@/styles/index.scss';

.resource-uploader {
    input[type="file"] {
        display: none;
    }

    .file-upload-label {
        cursor: pointer;
        padding: 20px 30px 20px 30px;
        color: white;
        background: var(--v-accent-base);
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        border-radius: 5px;
    }

    .file-upload-label.v-btn--disabled {
        background: rgba(0, 0, 0, .12) !important;
        color: rgba(0, 0, 0, .26) !important;
    }
}
