.v-menu__content {
  max-height: 80vh;
  overflow-y: auto;
}

.mobile {
  .v-menu__content {
    z-index: 10000000 !important;
  }
}

.v-menu__content .base-list-actions {
  .v-subheader {
    height: 30px !important;
  }
}