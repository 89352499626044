@import '~@/styles/index.scss';

.jobs-header {
    font-size: 18px;
}
.job-list {
    padding: 5px;
}
.job-icon {
    margin-right: 10px;
    color: #000000 !important;
    opacity: 0.5;
}
.job-icon-delete {
    color: white !important;
}
.job {
    font-size: 14px;
    opacity: 0.9;
    padding: 10px 14px 10px 14px !important;
    margin-top: 5px;
    justify-content: normal !important;
    overflow: hidden;
    white-space: nowrap;
    border-radius:6px;
}
.job.running {
    background-color: #EEEEEE;
}
.job.success {
    background-color: #7ea450;
}
.job.failed {
    background-color: #be3333;
}

