@import '~@/styles/index.scss';

.the-notification {
    opacity: 0.9;
    .v-snack__content {
        padding: 14px !important;
        justify-content: normal !important;
    }

    .notification-icon {
        margin-right: 10px;
        color: #000000 !important;
        opacity: 0.5;
    }
}

.desktop {
    .the-notification {
        border-top: 64px solid transparent;
    }
}

.mobile {
    .the-notification {
        margin: 10px 10px 0 10px;

        .v-snack__wrapper {
            border-radius: 5px !important;
        }
    }
}
