@import '~@/styles/index.scss';

.base-bottom-sheet {
    width: 100%;
    .v-card__title {
        height: 50px;
        padding: 0 !important;

        .v-toolbar .v-btn--flat {
            margin-right: 0;
        }
    }

    .v-card__text {
        max-height: calc(90vh - 55px);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding: 16px 16px 24px 16px;
    }

    .v-input {
        margin-bottom: 12px;
    }

    .v-btn__content {
        font-size: 15px !important;
    }
}

.base-bottom-sheet.tabs {
    .v-window {
        padding-top: 5px;
        max-height: calc(90vh - 55px - 48px - 5px);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    form {
        padding: 16px 20px 24px 20px;
    }

    .vue-recycle-scroller {
        .v-subheader {
            padding-left: 32px !important;
        }

        padding-bottom: 10px;
        padding-top: 5px;
    }

    .v-card__text {
        padding: 0;
        max-height: 100%;
        overflow-y: hidden;
    }
}

.base-bottom-sheet.list {
    .v-card__text {
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
    }

    .v-list {
        padding-top: 0;
    }
}
