@import '~@/styles/index.scss';

.folder-structure-selector {
    height: 80vh !important;
    min-width: 80% !important;
    max-width: 80% !important;
    margin: 0 !important;

    .folder-structure {
        height: 100% !important;
    }

    .v-card__text {
        padding: 0 !important;
        overflow-y: hidden !important;

        .folder-structure-grid {
            min-height: 100% !important;
        }
    }

    .base-toolbar {
        position: absolute;
        top: 56px !important;
    }
}

.mobile .folder-structure-selector {
    max-width: 100% !important;

    .v-card__title {
        display: none;
    }

    .title-heading {
        position: absolute;
        top: 0 !important;
        padding-top: 12px;
    }

    .item-grid {
        margin-top: 107px !important;
        height: calc(70vh - 101px) !important;
        padding: 0 !important;
        overflow-x: hidden;
    }

    .folder-structure-grid {
        padding: 0 0 0 5px !important;
        min-width: 100% !important;

        .folder-structure-item {
            margin-left: 10px !important;
            width: calc(50vw - 18px) !important;
        }
    }
}

.desktop .folder-structure-selector {
    .item-grid {
        padding: 0 6px 0 6px !important;
        margin-top: 58px;
    }

    .folder-tree {
        margin-top: 58px;
    }
}
