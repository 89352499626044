.v-list {
}

.base-list-actions {

}

.mobile {
  .base-list-actions {
    padding-top: 0 !important;
    padding-bottom: 15px !important;
  }
}

.v-list-item__action:first-child, .v-list-item__icon:first-child {
  margin-right: 15px;
}