@import '~@/styles/index.scss';

.base-toolbar {
    z-index: 2;
    padding-right: 0;
    height: 70px !important;
    overflow-y: hidden;
    width: 100%;

    .search-field {
        margin: 0 10px 0 10px;
    }

    transition: none !important;
    overflow-x: auto;

    .v-toolbar__content {
        padding: 18px 24px 0 24px !important;
    }
}

.base-toolbar.dense {
    .v-toolbar__content {
        padding: 0 14px 0 14px !important;
    }
}

.tablet .base-toolbar {
    width: calc(100vw - 240px);
}

.mobile .base-toolbar {
    width: 100vw;

    .v-toolbar__content {
        padding: 0 12px 0 16px !important;

        .v-toolbar__title {
            //margin-left: 10px;
            padding-left: 5px !important;
        }
    }
}
