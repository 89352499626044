@import './datatable.scss';
@import './tabpanel.scss';
@import './menu.scss';
@import './list.scss';
@import './select.scss';
@import './stepper.scss';
@import './button.scss';
@import './column.scss';

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 15px;
}

