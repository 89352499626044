@import './custom/index.scss';
@import './vuetify/index.scss';

html {
  //overflow: hidden;
  overscroll-behavior: none !important;
}

.elevation-soft {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .10) !important;
}

.v-data-table td, .v-data-table th {
  vertical-align: middle !important;
}

// CSS for removing the chrome address bar height takeover (loose the smooth scroll effect)

/*
.mobile.logged-out.application {
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: auto !important;

  .application--wrap {
    height: 100%;
    min-height: 100% !important;
  }
}
*/

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: var(--v-background-base) !important;
}

.ppds-theme {
  font-family: 'Inter', sans-serif;

  .mirage-main-view {
    background: linear-gradient(180deg, #F5F9FC 75%, #FFFFFF 100%) !important;
  }
}

