@import '~@/styles/index.scss';


.search-field.auto-shrink {
    max-width: 40px;
    cursor: pointer;
}

.search-field {
    padding: 3px 8px 3px 8px !important;
    margin: 0 5px 0 5px;
    background-color: #f1f3f4;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    max-width: 300px;

    .v-input__slot::before, .v-input__slot::after {
        display: none !important;
    }
}

.auto-shrink.v-input--is-focused {
    cursor: text;
    max-width: 300px !important;
}

.auto-shrink.active-search {
    cursor: text;
    max-width: 300px !important;
}

.theme--dark {
    .search-field {
        background-color: var(--v-background-lighten1);
    }
}
