@import '~@/styles/index.scss';

.base-dialog {
    overflow-y: visible;
    overflow-x: visible;
    min-width: 350px;

    .v-card__title {
        height: 60px;
        padding-top: 8px;
        //padding: 0 24px 0 28px;

        .v-toolbar__title {
            max-width: calc(100% - 48px);
        }
    }

    .v-card__actions {
        padding: 16px 22px 18px 22px;
    }

    .v-card__text {
        padding: 12px 36px 12px 36px;
    }

    .v-input {
        margin-bottom: 15px;
    }

    form {
        padding-top: 10px;
    }
}

.base-dialog.tabs {
    overflow-y: hidden;

    .v-card__text {
        padding: 0;
    }

    .v-tabs-bar__content {
        margin-left: 24px;
        margin-right: 24px;
        border-bottom: 2px solid rgb(241, 241, 241);
    }

    .v-tabs-bar {
        margin-bottom: 15px;
    }

    form {
        padding: 12px 36px 0 36px;
    }

    .vue-recycle-scroller {
        .v-subheader {
            padding-left: 36px !important;
        }
    }

    .v-window {
        padding-top: 10px;
        overflow-y: auto;
        max-height: calc(90vh - 60px - 76px - 48px - 10px);
    }
}

.base-dialog.list {
    .v-card__text {
        padding: 8px 0 0 0;
    }

    .v-list-item {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.base-dialog.list.hide-all-actions {
    .v-card__text {
        padding: 8px 0 30px 0;
    }
}
