@import '~@/styles/index.scss';

.image-editor {
    img {
        max-width: 100%;
    }

    .cropper-container {
        max-height: calc(90vh - 260px);
    }
}
