.v-data-table {
  td {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .v-input__slot {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .table-actions {
    padding: 0 !important;
    padding-right: 3px !important;
    min-width: 39px !important;
    max-width: 39px !important;
    width: 39px !important;

    .v-btn {
      margin: 0;
    }
  }

  .inline-menu {
    div {
      height: 100%;
      width: 100%;
    }

    .span-container {
      display: table;

      span {
        display: table-cell;
        vertical-align: middle;
      }
    }
  }

  .icon-text-cell {
    vertical-align: middle;

    .icon-cell {
      margin-right: 5px;
    }
  }

  .icon-cell {
    vertical-align: middle;
  }

  .icon-only-cell {
    width: 40px;
    min-width: 40px;
    max-width: 40px;
    padding: 0 5px 0 5px !important;
  }

  .text-cell {
    vertical-align: middle;
  }

  .chip-row {
    padding-bottom: 5px !important;
    padding-top: 10px !important;

    span {
      background-color: #e0e0e0;
      padding: 6px 12px 6px 12px;
      margin-bottom: 5px;
      float: left;
      margin-right: 5px;
      border-radius: 28px;
      vertical-align: middle;
      overflow: hidden;
      //box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
    }
  }

  thead {
    th {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
}

.theme--dark {
  .chip-row {
    span {
      background-color: #555;
    }
  }
}