@import '~@/styles/index.scss';

.tab-modal {
    .v-list-item {
        padding: 0 34px 0 34px;
    }

    .create-new-button {
        padding-left: 28px !important;
    }
}

.mobile {
    .v-tab {
        margin-left: 0 !important;
    }
}
