@import '~@/styles/index.scss';

.the-user-menu {
    padding-top: 15px;

    .v-subheader {
        height: 20px !important;
    }

    .language-menu {
        width: 100%;

        > div > div {
            width: 100%;
        }
    }
}
.versionText {
    color: gray;
    background: var(--v-background-base);
    text-align: center;
    font-size: 13px;
    font-style: italic;
}
