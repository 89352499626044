@import '~@/styles/index.scss';

.floating-action-button {
    right: 10px;
}

.floating-action-button.first {
    bottom: 55px;
}

.floating-action-button.second {
    bottom: 117px;
}

.floating-action-button.third {
    bottom: 178px;
}
