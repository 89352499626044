@import '~@/styles/index.scss';

.invalidIdp {
    display: block;
    position: relative;
    top: 100px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-size: 2em;
}

.invalidIdp .v-icon {
    font-size: 4em;
}

.invalidIdp .small {
    margin-top:40px;
    font-size:0.8rem;
    color:gray;
}
