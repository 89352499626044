@import '~@/styles/index.scss';

.linear-processing-indicator {
    margin: 0;
    height: 5px;

    > div {
        height: 5px;
    }

    .v-progress-linear__background {
        height: 5px !important;
    }
}

.linear-processing-indicator.fixed {
    position: fixed;
    width: calc(100vw - 56px);
}

.circle-processing-indicator {
    top: 12px;
    right: 12px;
    position: fixed;
}
