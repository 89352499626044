@import '~@/styles/index.scss';

.help-message-indicator {
    position: absolute;
    font-size: 44px;
    height: 50px;
    width: 50px;
    background: #eeeeee;
    border-radius: 25px;
    border: 1px solid var(--v-accent-base);
    color: var(--v-accent-base);
    animation-name: helpMessageIndicatorAnim;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    pointer-events: none;
}

.hide-help-indicator .help-message-indicator {
    display: none;
}

@keyframes helpMessageIndicatorAnim {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0.7;
        transform: scale(1.3);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
