@import '~@/styles/index.scss';

.tablet {
    .v-main {
        padding-left: 240px !important;
    }
}

div[id^=__lpform_] {
    display: none;
}
