::-webkit-scrollbar-track {
  background-color: var(--v-background-base);
}

::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--v-scrollbarThumb-base);
  height: 6px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  cursor: grab;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--v-scrollbarThumbHover-base);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}