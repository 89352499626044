.floating-action-button.pos-primary {
  bottom: 55px;
  right: 10px;
}

.floating-action-button.pos-secondary {
  bottom: 117px;
  right: 10px;
}

.floating-action-button.back-to-top {
  bottom: 180px;
  right: 18px;
}