@import '~@/styles/index.scss';


.mobile .lat-long-selector {
    .vue-map-container {
        min-height: 60vh;
    }

    .row {
        .v-input {
            width: calc(50% - 8px);
        }
    }
}
