.empty-text {
  width: 100%;
  text-align: center;
  padding: 0 16px 0 16px;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}

.theme--light {
  .empty-text {
    color: rgba(0, 0, 0, .38)
  }
}

.theme--dark {
  .empty-text {
    color: hsla(0,0%,100%,.5);
  }
}