@import '~@/styles/index.scss';

.resource-viewer-desktop {
    height: 95% !important;
    max-height: 95% !important;
    width: 95%;
    overflow: hidden;

    .v-card {
        display: flex !important;
        flex-direction: column;
    }

    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }

    .v-card__title {
        height: 60px;
        padding-top: 8px;
    }

    .preview {
        height: 100%;
    }

    .font-preview {
        max-width: 100%;
        max-height: 100%;
        padding: 30px;
        background: white;
        color: black;
        font-size: 50px;
        line-height: 60px;
    }

}

.resource-viewer-mobile {
    height: 90% !important;
    max-height: 90% !important;
    width: 100%;
    overflow: hidden;

    .v-card {
        display: flex !important;
        flex-direction: column;
    }

    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }

    .v-card__title {
        height: 60px;
        padding-top: 8px;
    }

    .preview {
        height: 50% !important;
    }

    .font-preview {
        max-width: 100%;
        max-height: 100%;
        padding: 30px;
        background: white;
        color: black;
        font-size: 24px;
        line-height: 24px;
    }
}

