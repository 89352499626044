@import '~@/styles/index.scss';


.v-application {
    .selected {
        .folder-item {
            background-color: var(--v-accent-base) !important;
            border-radius: 4px;
        }

        .resources-font-content {
            background-color: var(--v-accent-base) !important;
            border-radius: 4px;
            color: white !important;
        }
    }
}

.folder-structure-item {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 4px;
    transition: border 0.2s ease-in-out;

    .v-card {
        height: 100%;
        width: 100%;

        > div {
            height: 100%;
            width: 100%;
        }
    }

    .resources-wayfindingIcons-content {
        background-color: var(--v-thumbnailBackground-base);
    }

    .resources-logos-content {
        background-color: var(--v-thumbnailBackground-base);
    }

    .resources-font-content {
        font-size: 18px;
        padding: 12px;
        background: var(--v-background-base);
        color: black;
        height: 100%;
    }

    .folder-structure-item-title {
        position: absolute;
        text-align: center;
        min-height: 45px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: rgba(66, 66, 66, 0.8);
        color: white;
        overflow: hidden;
        padding: 4px 10px;
        overflow-wrap: anywhere;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .folder-item {
        height: 100%;
        width: 100%;
    }

    .large-icon {
        i {
            height: 100%;
            width: 100%;
            padding-bottom: 30px;
            font-size: 100px;
        }
    }

    .placeholder-div {
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background: var(--v-accent-base);
        -webkit-animation-name: placeholderPulse;
        -webkit-animation-duration: 3s;
        -webkit-animation-iteration-count: infinite;
        animation-name: placeholderPulse;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

    @keyframes placeholderPulse {
        from {
            background: var(--v-accent-base);
        }
        to {
            background: var(--v-primary-base);
        }
    }

    @-webkit-keyframes placeholderPulse {
        from {
            background: var(--v-accent-base);
        }
        to {
            background: var(--v-primary-base);
        }
    }
}

.mobile .folder-structure-item {
    width: calc(50vw - 20px);
    height: calc(50vw - 20px);
    margin-right: 0;
}
.overlay {
    font-size: 2px;
    display: inline-block;
    z-index: 9;
    white-space: nowrap;
    position: absolute;
    left: 5px;
    top: 5px;
}
.online {
    color: #709a41;
}

.offline {
    color: #b62325;
}
