@import '~@/styles/index.scss';

.folder-structure {
    height: calc(100vh - 64px);

    .root-item {
        max-height: 26px;
    }

    .root-item .v-icon {
        padding-right: 8px;
    }

    .folder-structure-grid {
        min-height: calc(100vh - 130px);
    }

    .row {
        margin: 0;
        width: 100%;
        height: calc(100% - 54px) !important;
    }

    .item-grid {
        height: 100%;
        overflow-y: auto;
    }

    .folder-tree {
        overflow-y: auto;
        height: 100%;
        padding-bottom: 10px;
        padding-left: 24px !important;
        max-width: 300px;

        .v-treeview {
            padding-top: 0;
        }

        .v-treeview-node {
            margin-top: 0;
        }

        .v-treeview-node__content {
            margin-top: 0;
        }

        .v-treeview-node__root {
            margin-top: 0;
        }
    }
}

.mobile .folder-structure {
    .item-grid {
        margin-top: 6px;
        padding: 0 4px 0 4px;
        height: auto;
        overflow-y: hidden;
    }
}

.mobileToggle {
    position: fixed;
    z-index: 10;
    left: 10px;
    bottom: 20px;
}
