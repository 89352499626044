@import '~@/styles/index.scss';

.v-app-bar .v-btn {
    margin: 0 !important;
}

.app-bar-logo:hover {
    cursor: pointer;
}

.ppds-theme {
    .the-app-bar {
        font-family: 'Gilroy', sans-serif !important;
        height: 72px;
        background-color: white !important;
    }
}

.uniguest-theme {
    .uniguest-logo {
        position: absolute;
        left: 0;
        right: 0;
        height: 30px;
        width: 125px;
        margin: 0 auto;
    }

    .truncate {
        width: 39%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
