.v-btn {
  margin: 0 5px;
}

.v-btn--text {
  .v-icon {
    left: -5px;
  }
}

.v-btn-toggle .v-btn {
  margin: 0;
}

.v-speed-dial__list {
  padding: 0;
}