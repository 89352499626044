.scrollable {
  overflow-y: auto !important;
}

.mobile {
  .scrollable {
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

.touch-scroll {
  -webkit-overflow-scrolling: touch !important;
}