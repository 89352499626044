@import '~@/styles/index.scss';

.title-heading {
    padding: 16px 16px 12px 16px;
    background-color: var(--v-background-base);
    width: 100%;

    .title {
        height: 36px;

        .v-toolbar__title {
            height: 36px;
            line-height: 35px;
            padding-left: 5px;
        }
    }

    .search {
        padding-top: 8px;

        .search-field {
            min-width: 100%;
        }

        .v-input {
            margin: 0 !important;
        }
    }
}

.title-heading.fixed {
    position: fixed;
    top: 0;
}
