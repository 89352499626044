@import '~@/styles/index.scss';

.tablet .the-main-drawer {
    width: 240px !important;
}

.theme--dark {
    .selected-route {
        background-color: lighten(#424242, 5%) !important;
    }
}

.bottom-spacer {
    height: 80px;
}

.the-main-drawer {
    overflow-y: hidden;

    .v-navigation-drawer__content {
        overflow-y: hidden;
    }

    .context-tile, .user-tile {
        height: 56px;

        .v-list-item__title {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .context-name{
        font-weight: 500;
        padding: 10px 15px;
        background-color: var(--v-backgroundAlt-base);
    }

    .user-tile {
        .v-list-item__title {
            font-size: 20px;
            font-weight: 400;
        }

        .v-list-item__action {
            margin-right: 0 !important;
        }
    }

    .v-list {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100%);
        padding: 12px 0 12px 0 !important;

        .v-list-item__title {
            font-weight: 500;
        }

        .v-list-group__header {
            margin-top: 5px;

            .v-list-item {
                padding-left: 0 !important;
            }
        }

        .route-item {
            transition: all 0.2s ease-in-out;
            margin-right: 5px;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            margin-top: 5px;
        }

        .route-item:before, .route-item:after {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        .selected-route {
            color: var(--v-primary-base) !important;
            border-left: 5px solid var(--v-primary-base);
            background-color: var(--v-hover-base);

            .v-icon {
                color: var(--v-primary-base) !important;
            }
        }
    }

    .v-subheader {
        height: 40px !important;
    }
}

.uniguest-theme {
    .the-main-drawer {

        .v-list-item {
            .v-list-item__title {
                font-weight: 600;
            }
        }

        .v-list-item--active {
            .v-list-item__title {
                color: #20A0DB;
            }
            .v-icon {
                color: #FFC52B;
            }
        }

        .selected-route {
            border-left: 5px solid #FFC52B !important;

            .v-list-item__title {
                color: #20A0DB;
            }

            .v-icon {
                color: #FFC52B !important;
            }
        }
    }
}
